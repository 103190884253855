import "../../styles/form.scss"
import {useEffect, useState} from "react";
import FormInput from "./FormInput";
import Button from "../../ui/Button";
import CheckBox from "../../ui/CheckBox";
import TextSmall from "../../ui/base/TextSmall";
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import useMobile from "../../useMobile";
import education from "../../json/education";
import {createPortal} from "react-dom";
import TextNormal from "../../ui/base/TextNormal";
import VND from "../../VND";
import ButtonLink from "../../ui/ButtonLink";

const Modal = ({ title, message, close }) => {
    
    return createPortal(
        (
            <div className="modal">
                <div className="modal-content">

                    <h2>{title}</h2>

                    <center>
                        <TextNormal>{message}</TextNormal>
                    </center>

                    <br/>

                    <ButtonLink onClick={close} style="normal">Закрыть</ButtonLink>

                </div>

                <div className="modal-bg" onClick={close}/>
            </div>
        ),
        document.getElementById('modal')
    )

}


const Form = () => {

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [telegram, setTelegram] = useState("")

    const [nameError, setNameError] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState("")
    const [telegramError, setTelegramError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState(null)

    const [scroll, setScroll] = useState(0)
    
    const isMobile = useMobile()

    useEffect(() => {
        const x = () => setScroll(window.pageYOffset || document.documentElement.scrollTop)
        window.addEventListener('scroll', x)
        return () => window.removeEventListener('scroll', x)
    }, [])


    const listener = () => window.scrollTo({ top: scroll })

    const close = () => {
        setModal(null)
        document.getElementById("modal").style.zIndex = -1
        window.removeEventListener('scroll', listener)
    }

    const open = (title, message) => {
        setModal(<Modal title={title} message={message} close={close} />)
        document.getElementById("modal").style.zIndex = 100
        window.addEventListener('scroll', listener)
    }

    const onPrivacyPolicy = () => window.open('privacy.pdf')

    const send = async () => {
        if(isLoading) return
        
        setIsLoading(true)

        if(name.length < 1) {
            setNameError("Введите имя")
            setIsLoading(false)
            return
        }

        if(phoneNumber.length < 8) {
            setPhoneNumberError("Неверный номер телефона")
            setIsLoading(false)
            return
        }

        if(telegram.length < 1) {
            setTelegramError("Неверный username")
            setIsLoading(false)
            return
        }

        const message = JSON.stringify({
            name,
            phone_number: phoneNumber,
            tg_username: telegram
        })

        try{

            const res = await fetch('/', {
                method: "post",
                body: message,
                headers: {
                    'Content-Type': "application/json"
                }
            })
            const json = await res.json()

            if(json?.field) {
                if(json.field === 'name') setNameError(json.message)
                if(json.field === 'phone_number') setPhoneNumberError(json.message)
                if(json.field === 'tg_username') setTelegramError(json.message)
                return
            }

            open('Ваша заявка принята в обработку', 'Ожидайте звонка от менеджера')

            setName("")
            setPhoneNumber("")
            setTelegram("")

        } catch (err) {
            open('Что-то пошло не так', 'Мы обязательно все починим, попробуй чуть позже')
        }

        setIsLoading(false)

    }

    const onChange = (e) => {
        const value = e.target.value

        switch (e.target.name) {
            case "name": setName(value); setNameError(""); break;
            case "phoneNumber": setPhoneNumber(value); setPhoneNumberError(""); break;
            case "telegram": setTelegram(value); setTelegramError(""); break;
        }
    }

    return(
        <div className="form container" id="form">

            <div className="container-head">
                <h2>
                    <span className="container-head__colored">Оставьте заявку</span>
                    {" "}
                    <span>и ожидайте сообщение от менеджера</span>
                </h2>
            </div>

            <div className="form-form">
                <FormInput
                    value={name}
                    onChange={onChange}
                    placeholder={"Имя"}
                    error={nameError}
                    name={"name"}
                />

                <FormInput
                    value={phoneNumber}
                    onChange={onChange}
                    placeholder={"Телефон"}
                    error={phoneNumberError}
                    name={"phoneNumber"}
                />

                <FormInput
                    value={telegram}
                    onChange={onChange}
                    placeholder={"Username в Телеграм"}
                    error={telegramError}
                    name={"telegram"}
                />
            </div>

            <div className="form-agree">
                <CheckBox checked={true}></CheckBox>
                <div className="form-agree-container">
                    <TextSmall>
                        Я согласен с
                    </TextSmall>
                    <TextSmall onClick={onPrivacyPolicy} className="form-agree-container__button"> политикой конфиденцильности</TextSmall>
                </div>
            </div>

            <div className="form-space"></div>

            <Button style="big" disabled={isLoading} onClick={send}>Оставить заявку</Button>

            <div className="form-space"></div>

            { modal }

            <EffectsContainer>
                {
                    isMobile ?
                        <>
                            <Effect name="Smile" bottom={0} right={0} scale={1} />
                            <Effect name="WhiteStar" bottom={0} scale={.5} />
                            <Effect name="Star" left={0} scale={1} />
                        </>
                        :
                        <>
                            <Effect name="Smile" left={100} top={30}/>
                            <Effect name="WhiteStar" left={100} top={200} scale={.5} />
                            <Effect name="Star" left={300} top={350} />

                            <Effect name="WhiteStar" right={100} top={0} scale={.5} />

                            <Effect name="Spiral" bottom={-50} right={0} rotate={-30} scale={.6} />
                        </>
                }

            </EffectsContainer>

        </div>
    )

}

export default Form