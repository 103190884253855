import "../../styles/components/activities.scss"
import ActivitiesItem from "./ActivitiesItem";
import {useEffect, useRef, useState} from "react";
import Button from "../../ui/Button";
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import useMobile from "../../useMobile";
import activities from "../../json/activities";
import scroll from "../../scroll";


const Activities = () => {

    const [open, setOpen] = useState(false)
    const [touched, setTouched] = useState(false)
    const [list, setList] = useState([])

    const ref = useRef()

    const isMobile = useMobile()


    useEffect(() => {
        if(open) {
            setList(activities)
            setTouched(true)
        } else {
            setList(activities.slice(0, 6))
            if(touched) setTimeout(() =>  scroll('activities'))
        }
    }, [open])

    return (
        <div className="activities container" id="activities" ref={ref}>
            <div className="container-head">
                <h2>
                    <span>Дополнительные</span>
                    {" "}
                    <span className="container-head__colored">секции</span>
                </h2>
            </div>
            <div className="activities-body">
                { list.map(i => <ActivitiesItem item={i} key={i.title}/> )}
            </div>
            {
                open ?
                    (<Button onClick={() => setOpen(false)} style="long">Скрыть</Button>)
                    :
                    (<Button onClick={() => setOpen(true)} style="long">Все секции</Button>)
            }

            {/*<EffectsContainer>*/}
            {/*    {*/}
            {/*        isMobile ?*/}
            {/*            <>*/}
            {/*                <Effect name="BottomArrow" left={"100px"} top={-70} scale={.4} />*/}
            {/*            </>*/}
            {/*            :*/}
            {/*            <>*/}
            {/*                <Effect name="BottomArrow" left={"calc(50% - 350px)"} top={-50} />*/}
            {/*            </>*/}
            {/*    }*/}
            {/*</EffectsContainer>*/}
        </div>
    )
}

export default Activities