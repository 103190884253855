const teachers = [
    {
        name: 'Богуславская Ольга',
        about: "Преподаватель русского языка и литературы. Стаж 25 лет, экспертный уровень МЦКО, постоянный проверяющий ЕГЭ/ОГЭ, учитель высшей категории.",
        img: "BoguslavskayaOlga.JPEG"
    },
    {
        name: "Черенкова Елена",
        about: "Преподаватель математики. Стаж более 15 лет, учитель высшей категории",
        img: "CherenkovaElena.JPG"
    },
    {
        name: "Абрамова Анна",
        about: "Преподаватель английского языка, председатель цикловой комиссии, уровень С1",
        img: "AbramovaAnna.JPEG"
    },
    {
        name: "Белостоцкий Дмитрий",
        about: "Преподаватель ораторского мастерства. Стаж - 23 года. Коуч, бизнес-тренер, действующий предприниматель",
        img: "BelostockijDmitrij.JPEG"
    },
    {
        name: "Виноградов Владимир",
        about: "Преподаватель русского языка и литературы. Стаж 8 лет. Успешно готовит к ЕГЭ/ОГЭ, разрабатывает курсы по русскому языку для непрофильных специалистов",
        img: "VinogradovVladimir.JPEG"
    },
    {
        name: "Шматов Артём",
        about: "Преподаватель географии. Стаж 5 лет",
        img: "ShmatovArtyom.JPEG"
    },
    {
        name: "Салахутдинов Ахнаф",
        about: "Преподаватель химии и биологии. Кандидат биологических наук. Защита – в МГУ им. М.В. Ломоносова. Член Научного Совета Академии Наук СССР и России. Автор более 70 научных публикаций, редактор и рецензент нескольких научных сборников",
        img: "SalahutdinovAhnaf.JPEG"
    },
    {
        name: "Куцов Руслан",
        about: "Преподаватель физики, теории вероятности и математики. Стаж 17 лет. Кандидат физико-математических наук. Является автором учебных пособий для подготовки к ЕГЭ",
        img: "KucovRuslan.JPEG"
    },
    {
        name: "Гремячева Светлана",
        about: "Учитель начальной школы. Стаж 29 лет. Специалист по эвристике, работает в игровой методологии",
        img: "GremyachevaSvetlana.JPEG"
    },
]

export default teachers