
const Arrow = ({
    side, // left | right,
    onClick,
    disabled = false
}) => {

    return (
        <div
            className={`arrow arrow-${side} ${disabled ? "disabled" : ""}`}
            onClick={() => !disabled && onClick()}
        >
            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7273 0.999512L2 8.99951L10.7273 16.9995" stroke="#242424" strokeWidth="2"/>
            </svg>
        </div>
    )

}

export default Arrow