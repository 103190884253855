import TextBig from "../../ui/base/TextBig";
import {useState} from "react";
import ToggleList from "../../ui/ToggleList";
import TextNormal from "../../ui/base/TextNormal";
import VND from "../../VND";




const ActivitiesItem = ({
    item
}) => {

    const {
        title,
        age,
        duration,
        pass,
        trial,
        individual
    } = item

    const [open, setOpen] = useState(false)

    function declOfNum(n, text_forms) {
        n = Math.abs(n) % 100;
        const n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    }

    const getcount = () => {
        if(!pass.count) return ""
        const forms = ['занятиe', 'занятия', 'занятий']
        return " (" + pass.count + " " + declOfNum(pass.count, forms) + ")"
    }

    return (
        <div className="activities-item">

            <TextBig
                onClick={() => setOpen(!open)}
                className="activities-item__title"
            >
                {title}
            </TextBig>
            <TextNormal
                className="activities-item__age"
                onClick={() => setOpen(!open)}
            >
                Возрастная группа {age} лет
            </TextNormal>
            <div
                className="activities-item__toggler"
                onClick={() => setOpen(!open)}
            >
                <ToggleList open={open} />
            </div>

            {
                // open && (
                    <div className={`activities-item-content ${open ? "open" : "close"}`}>
                        <TextNormal className="activities-item__duration">Продолжительность занятия {duration}</TextNormal>
                        {
                            pass &&
                                <TextNormal>
                                    Абонимент: {VND(pass.price)}{pass.per_hour && "/час"}{getcount()}
                                </TextNormal>
                        }
                        {
                            trial &&
                            <TextNormal>
                                Пробное занятие: {VND(trial.price)}{trial.per_hour && "/час"}
                            </TextNormal>
                        }
                        { individual &&
                            <TextNormal>
                                Индивидуальное занятие: {VND(individual.price)}{individual.per_hour && "/час"}
                            </TextNormal>
                        }
                    </div>
                // )
            }
        </div>
    )
}

export default ActivitiesItem