
import TextNormal from "../../ui/base/TextNormal";
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import ButtonLink from "../../ui/ButtonLink";
import useMobile from "../../useMobile";
import scroll from "../../scroll";
import "../../styles/components/head.scss"

const Head = () => {

    const isMobile = useMobile()

    const onClick = () => {
        if(isMobile) {
            scroll("about", 200)
        } else {
            scroll("about")
        }
    }

    return(

        <div className="head blank">

            <div className="head-logo">
                <img
                    className="head-logo__img"
                    src="img/biglogo.png"
                    alt="Логотип"
                    height={400}
                    width={800}
                />
                <EffectsContainer>
                    {
                        isMobile ?
                            <>
                                <Effect name="RightSplash" right={-90} top={-80} scale={.2}/>
                                <Effect name="RightSplash" left={-90} bottom={-60} rotate={180} scale={.2} />
                            </>
                            :
                            <>
                                <Effect name="RightSplash" right={-150} top={0} />
                                <Effect name="RightSplash" left={-150} bottom={0} rotate={180} />
                            </>
                    }
                </EffectsContainer>
            </div>

            <div className="head-text">
                <TextNormal>
                    Русская школа в г. Нячанг, Вьетнам
                </TextNormal>

                <TextNormal>
                    Образование без границ - вместе к новым горизонтам!

                </TextNormal>
            </div>

            <ButtonLink onClick={() => window.open("https://lms.the-1st-academy.com/", "_blank")}>
                Вход в Личный Кабинет
            </ButtonLink>

            <div className="head-bottom" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none">
                    <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z" fill="#0F0F0F"/>
                </svg>
            </div>

            <EffectsContainer>
                {
                    isMobile ?
                        <>
                            <Effect name="WhiteStar" left={0} top={0} scale={.5} />
                            <Effect name="WhiteStar" right={-20} bottom={80} scale={.5} />

                            <Effect name="Star" left={0} bottom={50} scale={.5} rotate={0} />
                            <Effect name="Star" right={20} bottom={50} scale={.5} rotate={-45} />
                        </>
                        :
                        <>
                            <Effect name="WhiteStar" left={0} top={60} />
                            <Effect name="WhiteStar" right={0} top={350}  />

                            <Effect name="Star" left={0} bottom={100} rotate={0} />
                            <Effect name="Star" right={100} bottom={100}  rotate={-45} />
                        </>
                }
            </EffectsContainer>

        </div>
    )

}

export default Head