const education = [
    {
        name: "Начальная школа",
        price: 8_000_000,
        info: [
            'Аттестат гос. образца РФ',
            'Уроки соответсвуют нормам ФГОС',
            'Комплексное дополнительное образование',
            'Авторские педагогические методики',
            'Скидочная программа',
            'Отличные обеды и завтраки',
        ]
    },
    {
        name: "Средняя школа",
        price: 9_000_000,
        info: [
            'Аттестат гос. образца РФ',
            'Уроки соответсвуют нормам ФГОС',
            'Комплексное дополнительное образование',
            'Авторские педагогические методики',
            'Скидочная программа',
            'Отличные обеды и завтраки',
        ]
    },
    {
        name: "Старшая школа",
        price: 10_000_000,
        info: [
            'Аттестат гос. образца РФ',
            'Уроки соответсвуют нормам ФГОС',
            'Комплексное дополнительное образование',
            'Авторские педагогические методики',
            'Скидочная программа',
            'Отличные обеды и завтраки',
        ]
    },
    {
        name: "Подготовка к ОГЭ / ЕГЭ",
        price: 2_000_000,
        per_mouth: true,
        info: [
            'Групповая и индивидуальная подготовка к ЕГЭ и ОГЭ специалистами высокого уровня, как в рамках учебной программы, так и во внеклассном режиме дополнительных занятий.',
        ]
    },
]

export default education