const activities = [
    {
        title: "Подготовка к школе",
        age: "5-6",
        duration: "2ч 45мин",
        pass: {
            price: 3_500_000,
            count: 12
        },
        trial: {
            price: 400_000,
        },
    },
    {
        title: "ИЗОСтудия",
        age: "5+",
        duration: "90 минут",
        pass: {
            price: 1_200_000,
            count: 8
        },
        trial: {
            price: 150_000,
        },
        individual: {
            price: 200_000
        }
    },
    {
        title: "Театральная студия",
        age: "5-14",
        duration: "90 минут",
        pass: {
            price: 1_500_000,
            count: 8
        },
        trial: {
            price: 150_000,
        },
    },
    {
        title: "Техно клуб",
        age: "6-12",
        duration: "60 минут",
        pass: {
            price: 2_000_000,
            count: 8
        },
        trial: {
            price: 250_000,
        },
    },
    {
        title: "Шахматы",
        age: "7+",
        duration: "45 минут",
        pass: {
            price: 1_200_000,
            count: 8
        },
        trial: {
            price: 150_000,
        },
        individual: {
            price: 250_000
        }
    },
    {
        title: "3D моделирование (Blender)",
        age: "10+",
        duration: "60 минут",
        individual: {
            price: 200_000
        }
    },
    {
        title: "Art digital (Adobe Illustrator)",
        age: "10+",
        duration: "60 минут",
        individual: {
            price: 200_000
        }
    },
    {
        title: "Вокал",
        age: "5+",
        duration: "45 минут",
        individual: {
            price: 500_000
        }
    },
    {
        title: "Гитара",
        age: "12+",
        duration: "45 минут",
        trial: {
            price: 0
        },
        individual: {
            price: 250_000
        }
    },
    {
        title: "Франзуский язык",
        age: "7+",
        duration: "60 минут",
        pass: {
            price: 1_400_000,
            count: 8
        },
        trial: {
            price: 200_000
        },
        individual: {
            price: 250_000
        }
    },
    {
        title: "Футбол",
        age: "4-6",
        duration: "60 минут",
        pass: {
            price: 3_500_000,
            count: 12
        },
        trial: {
            price: 400_000
        },
        individual: {
            price: 200_000
        }
    },
    {
        title: "Танцы/ОФП/Йога",
        age: "5+",
        duration: "60 минут",
        pass: {
            price: 800_000,
            count: 6
        },
        trial: {
            price: 150_000
        },
        individual: {
            price: 250_000
        }
    },
    {
        title: "Общая физическая подготовка",
        age: "4+",
        duration: "60 минут",
        pass: {
            price: 1_200_000,
            count: 12
        },
        trial: {
            price: 0
        }
    },
    {
        title: "Растяжка и гибкость",
        age: "12+",
        duration: "90 минут",
        pass: {
            price: 600_000,
            count: 4
        },
        trial: {
            price: 150_000
        },
        individual: {
            price: 250_000
        }
    },
    {
        title: "Группа выходного дня",
        age: "3+",
        duration: "60 минут - 7ч.",
        pass: {
            price: 110_000,
            per_hour: true,
            count: 8
        },
        trial: {
            price: 120_000,
            per_hour: true
        },
    },
]

export default activities