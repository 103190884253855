import TextBig from "../../ui/base/TextBig";


const FooterTop = () => {

    const scroll = () => window.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <div className="footer-top" onClick={scroll}>
            <svg width="75" height="63" viewBox="0 0 75 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M48.3137 52.9036C42.6412 50.8666 38.7711 46.9807 36.2985 42.3098C32.5647 35.2556 32.0158 26.4098 33.3185 19.349C34.1366 14.9113 36.568 11.9273 38.5694 7.6269C38.6504 7.45272 38.5427 7.27851 38.3287 7.23784C38.1158 7.19691 37.8756 7.30527 37.7957 7.4792C35.7708 11.8299 33.3269 14.8516 32.4988 19.3412C31.1781 26.5004 31.7411 35.4687 35.5269 42.622C38.0675 47.4219 42.0599 51.405 47.8893 53.4985C48.0857 53.5691 48.3395 53.4937 48.4567 53.3294C48.5739 53.1651 48.5101 52.9742 48.3137 52.9036Z" fill="#fff"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M38.0105 6.50474C38.0023 6.51996 37.9502 6.62188 37.8956 6.70467C37.6716 7.04872 37.2132 7.62982 36.5835 8.3417C34.4679 10.7281 30.4261 14.5756 26.6129 15.8093C26.3929 15.8805 26.2659 16.0711 26.3285 16.2351C26.3911 16.3991 26.6193 16.475 26.8393 16.4038C30.8069 15.1201 35.0433 11.1435 37.2438 8.66015C37.5383 8.32741 37.798 8.02146 38.0145 7.75257C37.9974 8.66544 37.95 9.57925 37.8893 10.4964C37.7132 13.1367 38.2618 15.3768 39.5481 17.6945C39.6336 17.8495 39.876 17.9037 40.0873 17.8149C40.2987 17.7269 40.4008 17.5285 40.3144 17.3737C39.0768 15.1427 38.5468 12.9862 38.7159 10.4448C38.795 9.2463 38.8511 8.05188 38.8501 6.86014C38.85 6.74154 38.8454 6.43377 38.836 6.39163C38.7827 6.15116 38.5114 6.15607 38.4512 6.15951C38.4221 6.16098 38.0601 6.19584 38.0105 6.50474ZM38.8275 6.52391C38.8287 6.51538 38.8308 6.5066 38.8318 6.4973C38.8315 6.50479 38.8293 6.51358 38.8275 6.52391Z" fill="#fff"/>
                </g>
                <defs>
                    <clipPath id="clip0_8201_971">
                        <rect width="64.0691" height="48.4077" fill="white" transform="translate(74.4385 46.9758) rotate(166.029)"/>
                    </clipPath>
                </defs>
            </svg>
            <TextBig>наверх</TextBig>
        </div>
    )

}

export default FooterTop