

const TextSmall = ({ children, className, itemprop = "", onClick }) => {

    const _class = 'text-small ' + className

    return (
        <span itemProp={itemprop} className={_class} onClick={onClick}>
            {children}
        </span>
    )

}

export default TextSmall