import TextNormal from "../../ui/base/TextNormal";
import "../../styles/components/guarantees.scss"
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import useMobile from "../../useMobile";


const Guarantees = () => {


    const isMobile = useMobile()

    return (
        <div className="guarantees blank">
            <div className="guarantees-item guarantees__text">
                <h2>
                    Обучение в нашей школе – это не просто получение знаний, это увлекательное приключение!
                    Нашим ученикам нравится учиться, они вдохновлены предметами, активны и любопытны
                    <EffectsContainer>
                        {
                            isMobile ?
                                <>
                                    {/*<Effect name="LeftSplashWhite" top={0} left={0} scale={.3} />*/}
                                    <Effect name="RotateWhite" bottom={-110} left={"calc(50% - 135px)"} scale={.3} />
                                </>
                                :
                                <>
                                    <Effect name="LeftSplashWhite" top={60} left={0} scale={.7} />
                                    <Effect name="RotateWhite" bottom={-60} left={"calc(50% - 135px)"} scale={.6} />
                                </>
                        }
                    </EffectsContainer>
                </h2>
            </div>

            <div className="guarantees-item guarantees__first">
                <img
                    src="img/g1.png"
                    alt="Занимаемся на уроках по современным методикам"
                    className="guarantees-item__image"
                    width={200}
                    height={150}
                />
                <TextNormal>Занимаемся на уроках по современным методикам</TextNormal>
            </div>
            <div className="guarantees-item guarantees__second">
                <img
                    src="img/g2.png"
                    alt="Учитываем индвивидуальные потребности ребенка"
                    className="guarantees-item__image"
                    width={200}
                    height={150}
                />
                <TextNormal>Учитываем индвивидуальные потребности ребенка</TextNormal>
            </div>
            <div className="guarantees-item guarantees__third">
                <img
                    src="img/g3.jpg"
                    alt="Способствуем развитию критического мышления у ребёнка"
                    className="guarantees-item__image"
                    width={200}
                    height={150}
                />
                <TextNormal>Способствуем развитию критического мышления у ребёнка</TextNormal>
            </div>
            <div className="guarantees-item guarantees__fourth">
                <img
                    src="img/g4.jpg"
                    alt="Даём знания и навыки, необходимые для успешной жизни"
                    className="guarantees-item__image"
                    width={200}
                    height={150}
                />
                <TextNormal>Даём знания и навыки, необходимые для успешной жизни</TextNormal>
            </div>
        </div>
    )

}

export default Guarantees