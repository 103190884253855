import Button from "../../ui/Button";
import TextLink from "../../ui/base/TextLink";
import "../../styles/components/header.scss"
import scroll from "../../scroll";

const Header = () => {

    return (
        <div className="header" id="header">
            <img className="header-logo" src="img/minilogo.png" alt=""/>

            <nav className="header-nav">
                <TextLink onClick={() => scroll('about')}>о нас</TextLink>
                <TextLink onClick={() => scroll('education')}>общее образование</TextLink>
                <TextLink onClick={() => scroll('activities')}>секции</TextLink>
            </nav>

            <div className="header-button">
                <Button onClick={() => scroll('form')}>оставить заявку</Button>
            </div>
        </div>
    )

}

export default Header