import TextNormal from "../../ui/base/TextNormal";
import ButtonLink from "../../ui/ButtonLink";
import "../../styles/components/education.scss"
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import VND from "../../VND";
import TextSmall from "../../ui/base/TextSmall";
import education from "../../json/education";
import useMobile from "../../useMobile";


const Modal = ({ title, close }) => {

    const { name, price, info, per_mouth} = education.find(i => i.name === title)

    return createPortal(
        (
            <div className="modal">
                <div className="modal-content">

                    <h2>{name}</h2>

                    { price && <center><TextNormal>Цена: {VND(price)} {per_mouth && "/ месяц"} </TextNormal></center>}

                    <span/>

                    { info.map(i => <TextSmall key={i}>{i}</TextSmall>)}

                    <span/>

                    <ButtonLink onClick={close} style="normal">Закрыть</ButtonLink>

                </div>

                <div className="modal-bg" onClick={close}/>
            </div>
        ),
        document.getElementById('modal')
    )

}


const EductionItem = ({
    title,
    desc,
    img,
}) => {

    const [modal, setModal] = useState(null)
    const [scroll, setScroll] = useState(0)

    useEffect(() => {

        const x = () => setScroll(window.pageYOffset || document.documentElement.scrollTop)
        window.addEventListener('scroll', x)

        return () => window.removeEventListener('scroll', x)

    }, [])

    const listener = () => window.scrollTo({ top: scroll })


    const close = () => {
        setModal(null)
        document.getElementById("modal").style.zIndex = -1
        window.removeEventListener('scroll', listener)
    }

    const open = () => {
        setModal(<Modal title={title} close={close} />)
        document.getElementById("modal").style.zIndex = 100
        window.addEventListener('scroll', listener)
    }


    return (
        <>
            <div className="education-item">
                <h3 className="education-item__title">{title}</h3>
                <TextNormal>{desc}</TextNormal>
                <img src={img} alt={title} width={100} height={100} className="education-item__img"/>
                <ButtonLink onClick={open} className="education-item__link">Подробнее</ButtonLink>
            </div>
            { modal }
        </>
    )
}


const Education = () => {

    const isMobile = useMobile()

    return(
        <div className="education container" id="education">
            <div className="container-head">
                <h2>
                    <span>Общее</span>
                    {" "}
                    <span className="container-head__colored">образование</span>
                </h2>

                <EffectsContainer>
                    {
                        isMobile ?
                            <>
                                <Effect name="LeftSplash" left={-60} top={-45} scale={.4} />
                                <Effect name="LeftSplash" right={-60} top={-45} rotate={90} scale={.4} />

                                <Effect name="Star" left={-80} top={-10} scale={.3}/>
                                <Effect name="WhiteStar" right={-80} top={-20} scale={.25} />
                            </>
                            :
                            <>
                                <Effect name="LeftSplash" left={-80} top={-40}  />
                                <Effect name="LeftSplash" right={-80} top={-40} rotate={90} />

                                <Effect name="Star" left={-250} top={0} scale={.8}/>
                                <Effect name="WhiteStar" left={-280} top={60} scale={.5} />

                                <Effect name="Star" right={-240} top={80} scale={.8}/>
                                <Effect name="WhiteStar" right={-320}  top={0} scale={.5} />
                            </>
                    }
                </EffectsContainer>

            </div>

            <div className="container-title">
                <TextNormal>
                    В нашей школе ученики получают аттестат государственного образца – такой же получают
                    ученики российских школ. С ним можно продолжить образование:
                    поступить в ВУЗ или в среднее специальное учебное заведение.
                </TextNormal>
                <TextNormal>
                    У нас есть разнообразные секции и кружки, индивидуальные занятия,
                    подготовка к экзаменам – для детей и взрослых.
                </TextNormal>
            </div>

            <div className="education-body">
                <EductionItem
                    title="Начальная школа"
                    desc="1-4 классы"
                    img="img/edu1.svg"
                />
                <EductionItem
                    title="Средняя школа"
                    desc="5-9 классы"
                    img="img/edu2.svg"
                />
                <EductionItem
                    title="Старшая школа"
                    desc="10-11 классы"
                    img="img/edu3.svg"
                />
                <EductionItem
                    title="Подготовка к ОГЭ / ЕГЭ"
                    desc="9-11 классы"
                    img="img/edu4.svg"
                />
            </div>


        </div>
    )

}

export default Education