import Header from "./components/Header/Header";
import About from "./components/About/About";
import About2 from "./components/About2/About2";
import Education from "./components/Education/Education";
import Activities from "./components/Activities/Activities";
import Guarantees from "./components/Guarantees/Guarantees";
import Trial from "./components/Trial/Trial";
import Form from "./components/Form/Form";
import Footer from "./components/Footer/Footer";
import Head from "./components/Head/Head";
import Slider from "./components/Slider/Slider";



const App = () => {

    return (
        <>
            <Header/>
            <Head/>
            <About/>
            <Education/>
            <Activities/>
            <Guarantees/>
            {/*<About2/>*/}
            <Trial/>
            <Slider/>
            <Form/>
            <Footer/>
        </>
    )

}

export default App