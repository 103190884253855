

const TextNavigation = ({ children, className }) => {

    const _class = 'text-navigation ' + className

    return (
        <span className={_class}>
            {children}
        </span>
    )

}

export default TextNavigation