

const EffectsContainer = ({ children }) => {

    if(typeof window === 'undefined') return null

    return (
        <div className="effects">
            {children}
        </div>
    )

}

export default EffectsContainer