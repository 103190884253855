const VND = (value) => {
    if(value) {
        return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'VND',
        }).format(value)
    } else {
        return "Бесплатно"
    }
}

export default VND