import "../../styles/components/slider.scss"
import teachers from "../../json/teachers";
import {useState} from "react";
import TextNormal from "../../ui/base/TextNormal";

import { Swiper, SwiperSlide } from '../../swiper/swiper-react.mjs';
import '../../swiper/swiper.css';
import Arrow from "../../ui/Arrow";
import useMobile from "../../useMobile";


const SliderItem = ({ name, about, img, position = "" }) => {

    return (
        <div className={`slider-item ${position ? "slider-front" : ""}`}>
            <img className="slider-item__img" src={`img/teachers/${img}`} alt={name}/>
            {
                position === "front" &&
                <div className="slider-box">
                    <h3>{name}</h3>
                    <TextNormal>{about}</TextNormal>
                </div>
            }
        </div>
    )

}

const Slider = () => {

    const [currentSlide, setCurrentSlide] = useState(0)
    const [swiper, setSwiper] = useState({});

    const isMobile = useMobile()

    const getPosition = (index) => {
        if(isMobile) {
            return "front"
        } else {
            if(index === currentSlide) return "front"
        }
        return ""
    }

    const setSlide = (x) => {
        if(x.realIndex >= teachers.length - 1) {
            setCurrentSlide(0)
        } else {
            setCurrentSlide(x.realIndex + 1)
        }
    }


    return (
        <div
            className="slider blank"
        >

            <div className="container-head slider-head">
                <h2>
                    <span>Наши</span>
                    {" "}
                    <span className="container-head__colored">Преподаватели</span>
                </h2>
            </div>

            <div className="container-title">
                <TextNormal>
                    Профессионалы, которые раскрывают потенциал каждого ребенка
                </TextNormal>
            </div>

            <Swiper
                slidesPerView={isMobile ? 1 : 3}
                spaceBetween={30}
                className="slider-content"
                grabCursor={true}
                loop={true}
                onSlideChange={setSlide}
                onInit={setSwiper}
            >
                {
                        teachers.map((i, index) =>
                            <SwiperSlide key={index}>
                                <SliderItem name={i.name} position={getPosition(index )} about={i.about} img={i.img} />
                            </SwiperSlide>
                        )
                }
            </Swiper>

            <div className="slider-buttons">
                <Arrow side="left" onClick={() => swiper?.slidePrev()} />
                <Arrow side="right" onClick={() => swiper?.slideNext()} />
            </div>

        </div>
    )

}

export default Slider