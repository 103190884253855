import TextNormal from "../../ui/base/TextNormal";
import "../../styles/components/about.scss"
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import useMobile from "../../useMobile";

const About = () => {

    const isMobile = useMobile()

    return (
        <div className="about blank" id="about">
            <div className="about-image">

                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A3f78af58087a23337e6af28e8e28497aebac11774a8c93c36a8502bdcd14d382&amp;source=constructor"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    title="ЯндексКарты"
                >
                </iframe>
            </div>


            <div className="about-text" id="about-text">

                <div className="container-head">
                    <h2><span>О НАС</span></h2>
                </div>

                <div className="container-title about-text-inner">
                    <TextNormal>
                        Добро пожаловать в русскую школу «The First Academy» во Вьетнаме, где ценности образования
                        выражаются через открытость, международную интеграцию, творчество и свободу.
                        В нашей школе каждый ученик воспринимается как уникальная личность,
                        и обучение строится с учетом индивидуальных особенностей.
                    </TextNormal>
                    <TextNormal>
                        В «The First Academy» уроки становится возможностью узнать что-то новое о мире вокруг,
                        развить память и логическое мышление, реализовать творческие идеи.
                        Мы верим, что образование без границ открывает двери к новым горизонтам, и мы гордимся тем,
                        что можем стать проводником в этом захватывающем путешествии.
                    </TextNormal>
                </div>


                <EffectsContainer>
                    {
                        isMobile ?
                            <>
                                <Effect name="WhiteStar" top={-10} left={"calc(50% - 52px)"} scale={.4} />
                                <Effect name="About" bottom={-20} left={"calc(50% - 80.5px)"} scale={.4} />
                            </>
                            :
                            <>
                                <Effect name="WhiteStar" top={0} left={"calc(50% - 52px)"} scale={.5} />
                                <Effect name="About" bottom={0} left={"calc(50% - 80.5px)"} scale={.6} />
                            </>
                    }
                </EffectsContainer>

            </div>
        </div>
    )

}

export default About