

const TextBig = ({ children, className = "", onClick }) => {

    const _class = 'text-big ' + className

    return (
        <span
            className={_class}
            onClick={onClick}
        >
            {children}
        </span>
    )

}

export default TextBig