

const Button = ({
    children,
    onClick,
    style= "normal",
    disabled
}) => {

    let className = 'button text-button '

    if(disabled) className += " disabled "

    switch (style) {
        case "normal": className += "button-normal"; break;
        case "big": className += "button-big"; break;
        case "long": className += "button-big button-long"; break;
    }

    return (
        <button className={className} onClick={onClick}>
            {children}
        </button>
    )

}

export default Button