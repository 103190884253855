
const scroll = (id, add = 0) => {
    const x = document.getElementById(id)

    let offsetTop = x.offsetTop
    const height = x.clientHeight

    if(window.innerWidth <= 1200) add -= 30

    window.scrollTo({
        top: offsetTop - (window.innerHeight  / 2 - height / 2) + add,
        behavior: "smooth"
    })
}

export default scroll