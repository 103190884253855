import "../../styles/components/trial.scss"
import Button from "../../ui/Button";
import scroll from "../../scroll";
import EffectsContainer from "../Effects/EffectsContainer";
import Effect from "../Effects/Effect";
import useMobile from "../../useMobile";
import TextBig from "../../ui/base/TextBig";

const Trial = () => {

    const isMobile = useMobile()


    return (
        <div className="trial container">
            <div className="container-head">
                <h2>
                    <span>Записывайтесь к нам на </span>
                    <span className="container-head__colored">пробный урок,</span>
                    <span> если вы:</span>
                </h2>
                <EffectsContainer>
                    {
                        isMobile ?
                            <>
                                <Effect name="Line" bottom={-40} left={"calc(50% - 270px)"} scale={.4}/>
                            </>
                            :
                            <>
                                <Effect name="Line" bottom={-40} scale={.6} />
                                <Effect name="RightSplash" top={-100} right={-80} scale={.4}/>
                            </>
                    }
                </EffectsContainer>
            </div>

            <div className="trial-cardbox">
                <div className="trial-card">
                    <TextBig>
                        Хотите оценить стиль и качество образования в нашей школе
                    </TextBig>
                </div>
                <div className="trial-card">
                    <TextBig>
                        Ищете учебное заведение с высоким уровнем преподавания и экспертизы
                    </TextBig>
                </div>
                <div className="trial-card">
                    <TextBig>
                        Хотите попасть в дружное сообщество умных и творческих людей
                    </TextBig>
                </div>
            </div>

            <Button style="big" onClick={() => scroll('form')}>
                Записаться на пробный урок
                <EffectsContainer>
                    {
                        isMobile ?
                            <>
                                <Effect name="LeftSplash" left={-70} top={-10} rotate={-50} scale={.6}/>
                                <Effect name="LeftSplash" right={-70} top={-20} rotate={130} scale={.6}/>
                            </>
                            :
                            <>
                                <Effect name="LeftSplash" left={-90} bottom={-5} rotate={-40} scale={0.7}/>
                                <Effect name="LeftSplash" right={-90} bottom={-5} rotate={130} scale={0.7}/>
                            </>
                    }
                </EffectsContainer>
            </Button>

        </div>
    )

}

export default Trial