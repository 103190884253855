import TextError from "../../ui/base/TextError";


const FormInput = ({
    value,
    name,
    onChange,
    placeholder,
    error,
    type = "text"
}) => {

    return(
        <div className="form-input">
            <input
                className={"t-caption"}
                type={type}
                value={value}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
            />
            { error && <TextError className="form-input-error">{error}</TextError>}
        </div>
    )

}

export default FormInput