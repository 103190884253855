

const TextLink = ({ children, className }) => {

    const _class = 'text-error ' + className

    return (
        <span className={_class}>
            {children}
        </span>
    )

}

export default TextLink