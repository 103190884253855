
const CheckBox = ({ checked }) => {

    return (
        <div className="checkbox">
            {
                checked ?
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                            <path d="M5.625 6.87329L7.5 8.74829L13.75 2.49829" stroke="black" strokeOpacity="0.58" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.125 7.49829V11.8733C13.125 12.2048 12.9933 12.5228 12.7589 12.7572C12.5245 12.9916 12.2065 13.1233 11.875 13.1233H3.125C2.79348 13.1233 2.47554 12.9916 2.24112 12.7572C2.0067 12.5228 1.875 12.2048 1.875 11.8733V3.12329C1.875 2.79177 2.0067 2.47383 2.24112 2.23941C2.47554 2.00499 2.79348 1.87329 3.125 1.87329H10" stroke="black" strokeOpacity="0.58" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_7996_742">
                                <rect width="15" height="15" fill="white" transform="translate(0 -0.00170898)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    :
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                            <path d="M13.125 7.49829V11.8733C13.125 12.2048 12.9933 12.5228 12.7589 12.7572C12.5245 12.9916 12.2065 13.1233 11.875 13.1233H3.125C2.79348 13.1233 2.47554 12.9916 2.24112 12.7572C2.0067 12.5228 1.875 12.2048 1.875 11.8733V3.12329C1.875 2.79177 2.0067 2.47383 2.24112 2.23941C2.47554 2.00499 2.79348 1.87329 3.125 1.87329H10" stroke="black" strokeOpacity="0.58" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_7996_745">
                                <rect width="15" height="15" fill="white" transform="translate(0 -0.00170898)"/>
                            </clipPath>
                        </defs>
                    </svg>
            }
        </div>
    )

}

export default CheckBox