

const TextLink = ({ children, className = "", onClick }) => {

    const _class = 'text-link ' + className

    return (
        <span className={_class} onClick={onClick}>
            {children}
        </span>
    )

}

export default TextLink