

const ToggleList = ({ open, onClick }) => {

    return (
        <div
            className={`togglelist ${open ? "open" : "disabled"}`}
            onClick={onClick}
        >
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10.9983H11M22 10.9983H11M11 10.9983V-0.00170898V21.9983" stroke="black" stroke-opacity="0.35" stroke-width="2"/>
            </svg>
        </div>
    )

}

export default ToggleList