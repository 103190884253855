
const ButtonLink = ({
    children,
    className,
    onClick
}) => {

    return (
        <button onClick={onClick} className={`button button-link text-button ${className ? className : ""}`}>
            { children }
        </button>
    )

}

export default ButtonLink